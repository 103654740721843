@import "~theme";

.fullWidth {
  height: 1px;
  width: 100%;
  background-color: #dbdbdb;
}

.IconContainer {
  flex-grow: 0!important;
  width: 48px;
  margin: 0 $spacing-unit;
}
