@import "~theme";

.root {
  height: 45px;
  width: 142px;
  background-color: $success-dark;
  border-radius: 23px;
  display: inline-flex;
  cursor: pointer;
  position: relative;

  @include mobile() {
    height: 32px;
    width: 94px;
  }
}

.label {
  flex: 1;
  height: 100%;
  display: inline-flex;
  align-items: center;
  padding-left: $spacing-unit * 1.5;
  color: $white;
}

.active {
  border: 2px solid $success-dark;
  background-color: $white;
  border-radius: 23px;
  color: $success-dark;
}
