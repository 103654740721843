@import "~theme";

.table {
  width: 100%;
  &.bordered {
    width: calc(100% - 2px);
  }
  td, th {
    padding: $spacing-unit * 2;
  }
}

.minWidth {
  min-width: 90px;
}

.head {
  font-family: $family-secondary;
  font-weight: $weight-semibold;
  font-size: $size-6;

  th {
    border: none;
    &.bordered {
      border: 1px solid #dbdbdb;
    }
  }
}
