@import "~theme";

.tag {
  box-sizing: border-box;
  font-family: $family-primary;
  display:inline-block;
  margin-bottom: $spacing-unit * 0.5;

  &.size-regular {
    padding: 12px;
    font-size: 14px;
    margin-right: 8px;
    line-height: 14px;
  }

  &.primary {
    border: 1px solid $grey-lighter;
    background: $white;
    color: $grey-light-new;
  }

  &.type-button:hover {
    cursor: pointer;
  }

  &.type-label:hover {
    cursor: default;
  }

  .icon {
    margin-left: $spacing-unit * 3;
    width: 8px;
    height: 8px;
    color: $black-bis;
    cursor: pointer;

    &:hover{
      cursor: pointer;
    }
  }
}
