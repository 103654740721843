@import "~theme";

.root{
  color: $black-bis;
  font-family: $family-primary;
  font-size: 1rem;
  line-height: 30px;
  vertical-align: middle ;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.checkbox{
  display: none !important;

  &:checked ~ span:after {
    transform: translate(-50%,-50%) scale(1);
  }
}

.checkboxContent{
  margin-left: $spacing-unit * 4;
}

.checkMark {
  width: 16px;
  height: 16px;
  border: 1px solid $black-bis;
  position: absolute;
  display: inline-block;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    background: $black-bis;
    transform: translate(-50%,-50%) scale(0);
  }
}

//.circleMark{
//  color: $black-bis;
//  width: 85px;
//  height: 85px;
//  border: 2px solid $black-bis;
//  border-radius: 50%;
//  display: inline-flex;
//  justify-content: center;
//  align-items: center;
//  cursor: pointer;
//
//  &.active, &:hover{
//    background-color: $black-bis;
//    color: $white;
//  }
//}
