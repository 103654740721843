@import "~theme";

.select {
  select {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09);

    &:hover,
    &:active,
    &:focus {
      box-shadow:  0 0 0 1px $black;
    }
  }
}
