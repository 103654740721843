.root {
    height: 0;
    overflow: hidden;
    transition: height ease;
}

.entered {
    height: auto;
    overflow: visible;
}

.wrapper {
    display: flex;
    width: 100%;
}

.wrapperInner {
    width: 100%;
}
