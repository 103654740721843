@import "~theme";

.flip-timer {
  .time {
    border-radius: 10px;
    text-align: center;
    position: relative;
    height: 45px;
    width: 48px;
    margin-right: $spacing-unit  * 0.5;
    margin-left: $spacing-unit  * 0.5;
    perspective: 480px;
    backface-visibility: hidden;
    transform: translateZ(0);
    transform: translate3d(0,0,0);
    background-color: inherit;
    @include desktop {
      height: 35px;
      width: 36px;
    }

    .count {
      background-color: inherit;
      filter: brightness(75%);
      display: block;
      font-family: $family-secondary;
      font-size: 30px;
      font-weight: 600;
      overflow: hidden;
      position: absolute;
      text-align: center;
      top: -1px;
      width: 100%;
      transform: translateZ(0);
      transform-style: flat;
      @include desktop {
        font-size: 24px;
      }

      &.top {
        border-radius: 4px 4px 0 0;
        height: 50%;
        transform-origin: 50% 100%;
      }

      &.bottom {
        background-color: inherit;
        filter: brightness(85%);
        border-radius: 0 0 4px 4px;
        line-height: 0;
        height: 50%;
        top: 50%;
        transform-origin: 50% 0;
      }

      &.curr {
        &.top {
          transform: rotateX(0deg);
          z-index: 3;
        }
      }

      &.next {
        &.bottom {
          transform: rotateX(90deg);
          z-index: 2;
        }
      }
    }

    .label {
      font-size: 11px;
      line-height: 1;
      margin-top: 5px;
      display: block;
      position: absolute;
      top: 100%;
      width: 100%;
      font-family: $family-secondary;
      font-weight: 600;
      color: currentColor;
      text-transform: uppercase;
      padding: 0;
      margin-left: -1px;
      @include desktop {
        top: calc(50% - 10px);
      }
    }

    &.days {
      @include desktop {
        margin-right: 36px;
      }

      .label {
        @include desktop {
          right: -38px;
        }
      }
    }

    &.hours {
      @include desktop {
        margin-right: 44px;
      }

      .label {
        @include desktop {
          right: -42px;
        }
      }
    }

    &.minutes {
      @include desktop {
        margin-right: 54px
      }

      .label {
        @include desktop {
          right: -44px
        }
      }
    }

    &.seconds {
      @include desktop {
        margin-right: 50px
      }

      .label {
        @include desktop {
          right: -44px
        }
      }
    }
  }

  .flip {
    .count {
      &.curr.top {
        transition: all 250ms ease-in-out;
        transform: rotateX(-90deg);
      }

      &.next.bottom {
        transition: all 250ms ease-in-out 250ms;
        transform: rotateX(0deg);
      }
    }
  }
}
