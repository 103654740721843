@import "~theme";

.radioGroup {
  display: flex;
}

.radioButton {
  display: flex;
  flex-grow: 1;
  font-family: $family-secondary;
  text-transform: uppercase;
  font-weight: $weight-bold;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $black;
  background-color: $white;
  min-width: 50px;
  cursor: pointer;
  border: $default-border;
  border-left: 0;
  padding: 1rem 0;
  &:first-child {
    border-left: $default-border;
  }
  &.checked,
  &:hover,
  &:active,
  &:focus {
    color: $white;
    background-color: $black;
  }

  &.disabled {
  //  TODO: add disabled styles when DS has one
  }

  input[type="radio"]{
    opacity: 0;
    position: absolute;
    cursor: pointer;
  }
}

.root{
  color: $black-bis;
  font-family: $family-primary;
  font-size: 1rem;
  line-height: 30px;
  vertical-align: middle ;
  display: inline-block;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;

  .radio{
    display: none;

    &:checked ~ span:after {
      transform: translate(-50%,-50%) scale(1);
    }
  }
}

.radioContent{
  margin-left: $spacing-unit * 5;
}

.radioMark {
  width: 16px;
  height: 16px;
  border: 1px solid $black-bis;
  position: absolute;
  display: inline-block;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 50%;

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    background: $black-bis;
    border-radius: 50%;
    transform: translate(-50%,-50%) scale(0);
  }
}
