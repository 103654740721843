@import "~theme";

.root {
  ul {
    align-items: stretch;
    padding-bottom: 9px;
  }
}

.item {
  font-family: $family-secondary;
  text-transform: uppercase;
  font-weight: $weight-bold;

  a {
    flex-direction: column;
    height: 100%;
    padding: 12px 6px;

    svg {
      margin: 3px 0;
    }
  }

  &.active {
    a {
      position: relative;
      border-color: $primary !important;
      &:after {
        position: absolute;
        border: solid transparent;
        width: 0;
        height: 0;
        content:'';
        top: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        border-width: 9px 9px 0 9px;
        border-top-color: $primary;
      }
    }
  }
}

.success {
  &:not(.active) {
    a {
      border-color: $success-dark !important;
      color: $success-dark;
      z-index: 2;
      &:hover {
        color: $success-dark;
      }
    }
  }
  &.active {
    a {
      border-color: $success-dark !important;
      background-color: $success-dark !important;
      &:after {
        border-top-color: $success-dark;
      }
    }
  }
}
