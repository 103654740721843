@import "~theme";

.root {
  display: inline-flex;
  padding: $spacing-unit;
  background-color: $white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 46px;
  align-items: center;
  font-size: 22px;
  width: 34px;
  height: 34px;
  margin-bottom: $spacing-unit;

  &[disabled] {
    background-color: $grey-lighter;
    color: $grey-light !important;
  }

  @include desktop() {
    &:hover:not([disabled]) {
      width: auto;
      padding-left: $spacing-unit * 2;
      padding-right: $spacing-unit * 2;
      cursor: pointer;

      .text {
        display: block;
      }
    }
  }
}

.text {
  display: none;
  margin-left: $spacing-unit;
}
