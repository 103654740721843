@import "~theme";

.wrapper {
  z-index: 1030;
}

.container {
  background-color: $white;
  flex: 0 1 auto;
  @include touch() {
    outline: none;
    width: calc(100% - 20px);
    margin: 0;
    max-width: 100%;
    z-index: 1031;
  }
}

.alert{
  background-color: $white;
  flex: 0 1 auto;
  padding: 20px;
  width: 315px;
  max-width: 90%;
  .content {
    overflow: hidden;
  }
}

.tabletAboveFixedSize{
  @include tablet() {
    width: 520px;
  }
}

.touchFullScreen{
  @include touch(){
    width: 100%;
    height: 100%;
    .content {
      max-height: 100vh;
    }
  }
}

.touchXFullScreen{
  @include touch(){
    width: 100%;
  }
}

.fixedSize{
  @include desktop() {
    width: 920px;
  }
}

.adaptive {
  @include desktop() {
    min-width: 640px;
    width: auto;
  }
  @include widescreen() {
    max-width: 1150px;
  }
}

.content {
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;

  @include desktop() {
    max-height: 80vh
  }
}

.buttonClose {
  position: absolute;
  top: -30px;
  right: -9px;
  z-index: 99;
  &:before {
    height: 4px;
  }
  &:after {
    width: 4px;
  }
  @include touch() {
    position: absolute;
    top: 5px;
    right: 5px;
    &:before,
    &:after {
      background-color: $black;
    }
  }
  &.btnWhite {
    &:before,
    &:after {
      background-color: $white;
    }
  }
}

.bottomClose{
  cursor: pointer;
  margin-top: $spacing-unit * 2;
  z-index: 3;
  & > span{
    margin-right: $spacing-unit * 1.5;
  }
}
