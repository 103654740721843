@import "~theme";

.root {
  padding: $spacing-unit * 3.5 0;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left {
  display: flex;
  align-items: center;
}

.title {
  margin-left: $spacing-unit * 3;

  @include mobile() {
    margin-left: $spacing-unit * 1.5;
  }
}

.icon {
  margin-right: $spacing-unit * 2.5;
}

.content {
  padding: $spacing-unit * 5;
  font-size: 18px;
  color: #666;
  display: none;

  @include mobile() {
    padding: $spacing-unit * 2.5 0;
    font-size: 16px;
  }
}

.show {
  display: block;
  transition: 0.65s;
}
